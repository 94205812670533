import MainPage from './component/mainpage/MainPage';
function App() {
  return (
    <main>
      <MainPage/>
    </main>
  );
}
export default App;



